import React, { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { reducer2 } from '../../reducer'; // Ensure your reducer handles SET_WISHLIST properly
import toast from 'react-hot-toast';
import { addToWishlistURL, removeFromWishlistURL, getWishlistURL } from "../../api/api";

export const WishlistContext = createContext();

const WishlistProvider = ({ children }) => {
    const initialstate = {
        Wishlist: JSON.parse(localStorage.getItem('wishlist')) || [], // Get Wishlist from localStorage if available
    };

    const [state, dispatch] = useReducer(reducer2, initialstate);

    const syncWishlistWithLocalStorage = (wishlist) => {
        localStorage.setItem("wishlist", JSON.stringify(wishlist));  // Ensure it's saved as a stringified JSON
    };
    
    // Fetch Wishlist (No changes here, already correct)
    const fetchWishlist = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) return;
    
            const { data } = await axios.get(getWishlistURL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            dispatch({ type: "SET_WISHLIST", payload: data });
            syncWishlistWithLocalStorage(data);  // Sync backend data to local storage
        } catch (error) {
            console.error("Error fetching wishlist:", error);
            toast.error("Failed to load wishlist from server.");
        }
    };

    // Add product to Wishlist (Update LocalStorage and Backend)
    const addToWishlist = async (product) => {
      try {
        const token = localStorage.getItem("token");
    
        if (!token) {
          toast.error("Please log in to add items to your wishlist.");
          return;
        }
    
        // Optimistic update: Add the product to the local state
        dispatch({ type: "ADD_TO_WISHLIST", payload: product });
        syncWishlistWithLocalStorage([...state.Wishlist, product]); // Sync with local storage
    
        // Destructure and remove `_id`
        const { _id, ...productWithoutId } = product;
    
        // Send request to backend
        await axios.post(addToWishlistURL, { product: productWithoutId }, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        toast.success("Item added to Wishlist");
      } catch (error) {
        console.error("Error adding to wishlist:", error);
    
        // Revert optimistic update if the request fails
        const revertedWishlist = state.Wishlist.filter(item => item.SKU_id !== product.SKU_id);
        dispatch({ type: "SET_WISHLIST", payload: revertedWishlist });
        syncWishlistWithLocalStorage(revertedWishlist);
    
        toast.error("Failed to add item to Wishlist.");
      }
    };
    

    // Remove product from Wishlist (Optimistic Update with LocalStorage)
    const removeFromWishlist = async (SKU_id) => {
        try {
          // Update local state optimistically
          const updatedWishlist = state.Wishlist.filter(item => item.SKU_id !== SKU_id);
          dispatch({ type: "REMOVE_FROM_WISHLIST", payload: SKU_id }); // Send SKU_id
          syncWishlistWithLocalStorage(updatedWishlist); // Sync with local storage
      
          // Update backend
          const token = localStorage.getItem("token");
          if (token) {
            await axios.delete(removeFromWishlistURL, {
              data: { SKU_id },
              headers: { Authorization: `Bearer ${token}` },
            });
          }
      
          toast.success("Item removed from Wishlist");
        } catch (error) {
          console.error("Error removing from wishlist:", error);
          toast.error("Failed to remove item from Wishlist.");
        }
      };
      
       
    // Sync Wishlist with backend when user logs in
    useEffect(() => {
        fetchWishlist();
    }, []);

    return (
        <WishlistContext.Provider value={{ ...state, addToWishlist, removeFromWishlist }}>
            {children}
        </WishlistContext.Provider>
    );
};

export default WishlistProvider;
