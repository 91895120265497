const wishlistReducer = (state, action) => {
  switch (action.type) {
    case "SET_WISHLIST":
      return { ...state, Wishlist: action.payload };

    case "ADD_TO_WISHLIST":
      // Check if the product already exists
      const productExists = state.Wishlist.some(item => item.SKU_id === action.payload.SKU_id);
      if (productExists) {
        return state; // No changes if the product already exists
      }
      return { ...state, Wishlist: [...state.Wishlist, action.payload] }; // Add new product

    case "REMOVE_FROM_WISHLIST":
      return {
        ...state,
        Wishlist: state.Wishlist.filter(item => item.SKU_id !== action.payload),
      };

    default:
      return state;
  }
};

export default wishlistReducer;
