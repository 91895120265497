import React, { createContext, useEffect, useReducer, useState } from 'react';
import { reducer1 } from '../../reducer';
import axios from 'axios';
import { getproductURL } from "../../api/api";

export const ProductContext = createContext();

const ProductContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(getproductURL);
                if (res.data && res.data.data) { // Ensure data is valid
                    localStorage.setItem('products', JSON.stringify(res.data.data)); 
                } else {
                    throw new Error("Invalid response format");
                }
            } catch (error) {
                setError("Error fetching data"); // Set error message
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // Set loading to false after data fetch
            }
        };
        getData();
    }, []);

    const getData = () => {
        const data = localStorage.getItem("Recent");
        if (!data || data === '[]') { // Check for empty data
            return [];
        } else {
            return JSON.parse(data);
        }
    };

    const initialstate = {
        RecentProduct: getData() || [],
    };
    const [state, dispatch] = useReducer(reducer1, initialstate);

    const addRecentProduct = (item) => {
        // Avoid duplicate products in the recent list
        const isProductInRecent = state.RecentProduct.some(product => product._id === item._id);
    
        if (!isProductInRecent) {
            dispatch({ type: "ADD_RECENT", payload: item }); // Dispatch only the new item
        }
    };
    
    

    useEffect(() => {
        if (state.RecentProduct.length > 0) {
            localStorage.setItem("Recent", JSON.stringify(state.RecentProduct));
        }
        // Expiration logic
        const expirationTimestamp = localStorage.getItem("RecentExpiration");
        const now = new Date().getTime();
    
        if (!expirationTimestamp || now > expirationTimestamp) {
            const expirationTime = now + 72 * 60 * 60 * 1000; // 72 hours from now
            localStorage.setItem("RecentExpiration", expirationTime);
        } else {
            const remainingTime = expirationTimestamp - now;
            const timeoutId = setTimeout(() => {
                localStorage.removeItem("Recent");
                localStorage.removeItem("RecentExpiration");
            }, remainingTime);
    
            return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
        }
    }, [state.RecentProduct]);
    

    return (
        <ProductContext.Provider value={{
            ...state,
            addRecentProduct,
            loading, // Provide loading state
            error, // Provide error state
        }}>
            {children}
        </ProductContext.Provider>
    );
};

export default ProductContextProvider;
