const cartReducer = (state = { Cart: [] }, action) => {
  switch (action.type) {
    case "ADD_TO_CART": {
      const existingProduct = state.Cart.find((item) => item.SKU_id === action.payload.SKU_id);

      if (existingProduct) {
        const updatedCart = state.Cart.map((item) =>
          item.SKU_id === action.payload.SKU_id
            ? { ...item, productcount: item.productcount + action.payload.productcount }
            : item
        );
        return { ...state, Cart: updatedCart };
      } else {
        return { ...state, Cart: [...state.Cart, action.payload] };
      }
    }

    case "REMOVE_ITEM": {
      const updatedCart = state.Cart.filter((item) => item.SKU_id !== action.payload);
      return { ...state, Cart: updatedCart };
    }

    case "SET_CART": {
      return { ...state, Cart: action.payload };
    }

    case "UPDATE_CART_ITEM": {
      const updatedCart = state.Cart.map((item) =>
        item.SKU_id === action.payload.SKU_id ? { ...item, productcount: action.payload.productcount } : item
      );
      return { ...state, Cart: updatedCart };
    }

    case "CLEAR_CART":
      return {
        ...state,
        Cart: [], // Reset the Cart array in state
      };

    default:
      return state;
  }
};

export default cartReducer;
