import React, { useState, useEffect } from 'react';
import { useWishlistContext } from '../../context';
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactStars from 'react-stars';

const ProductCard = ({ data = [] }) => {
    const { Wishlist, addToWishlist, removeFromWishlist } = useWishlistContext();
    const [localWishlist, setLocalWishlist] = useState([]);

    // Helper function to check if a product is in the wishlist from localStorage or context
    const isInWishlist = (productId) => {
        return localWishlist.some((wishlistItem) => wishlistItem.SKU_id === productId);
    };

    // Handle adding to wishlist
    const handleAddToWishlist = (item) => {
        if (!isInWishlist(item.SKU_id)) {
            addToWishlist(item); // Add to context
            setLocalWishlist([...localWishlist, item]); // Update local state for immediate UI update
        }
    };

    // Handle removing from wishlist
    const handleRemoveFromWishlist = (itemId) => {
        removeFromWishlist(itemId); // Remove from context
        setLocalWishlist(localWishlist.filter((item) => item.SKU_id !== itemId)); // Update local state
    };

    // Effect to sync with context and initialize localWishlist
    useEffect(() => {
        setLocalWishlist(Wishlist); // Sync wishlist with the context state
    }, [Wishlist]);

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 py-8">
            {data.map((item) => {
                const { _id, name, image, currency = '₹', MRP_price, selling_price, rating = 0, SKU_id } = item;

                // Handle both single image and array of images
                const imageUrl = Array.isArray(image) ? image[0] : image;

                return (
                    <div key={_id}>
                        <div className="relative w-full h-80">
                            <Link to={`/product/${_id}`}>
                                <img
                                    src={imageUrl}
                                    alt={name}
                                    className="w-full h-full object-cover"
                                    style={{ borderRadius: '15%' }}
                                />
                            </Link>
                            <button
                                className="absolute top-3 right-3 bg-white p-2 rounded-full hover:bg-gray-200 transition"
                                onClick={() => handleAddToWishlist(item)} // Call the add function
                            >
                                <FaHeart
                                    className={`text-gray-500 ${isInWishlist(SKU_id) ? 'text-red-500' : ''}`}
                                />
                            </button>
                        </div>
                        <div className="p-1 h-20" style={{ borderRadius: '10px', color: 'black', backgroundColor: '#f8e9ca' }}>
                            <h3 className="text-sm font-semibold truncate">{name}</h3>
                            <div className="flex justify-between items-center mt-2">
                                <span className="text-sm font-bold">{`${currency}${selling_price}`}</span>
                                {MRP_price && (
                                    <span className="line-through text-sm text-red-500">{`${currency}${MRP_price}`}</span>
                                )}
                            </div>
                            <div className="flex items-center">
                                <ReactStars
                                    count={5}
                                    value={rating}
                                    size={20}
                                    edit={false}
                                    activeColor="#ffd700"
                                />
                                <span className="ml-2 text-sm text-gray-600">({rating})</span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ProductCard;
