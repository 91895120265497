import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { getUserNotificationsURL, markNotificationAsReadURL } from "../../api/api";
import { useAuthContext } from "..";

export const NotificationContext = createContext();

const NotificationContextProvider = ({ children }) => {
    const [userNotifications, setUserNotifications] = useState([]);
    const [generalNotifications, setGeneralNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [hasUnreadGeneralNotifications, setHasUnreadGeneralNotifications] = useState(false);
    const { token } = useAuthContext();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNotifications = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setLoading(false);
                return; // Exit if no token is available
            }
    
            try {
                const response = await axios.get(getUserNotificationsURL, {
                    headers: { Authorization: `Bearer ${token}` },
                });
    
                const userSpecific = response.data.filter((notif) => notif.email !== null);
                const general = response.data.filter((notif) => notif.email === null);
    
                setUserNotifications(userSpecific);
                setGeneralNotifications(general);
                localStorage.setItem("userNotif", JSON.stringify(userSpecific));
                localStorage.setItem("generalNotif", JSON.stringify(general));
    
                // Calculate the unread notification count
                const unreadUserCount = userSpecific.filter((notif) => !notif.is_read).length;
                const generalUnread = general.some((notif) => !notif.is_read);
    
                setNotificationCount(unreadUserCount);
                setHasUnreadGeneralNotifications(generalUnread);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchNotifications();
    }, [token]); // Dependency on `token`
    

    // Function to mark notifications as read
    const markNotificationAsRead = (notification_id) => {
        const updatedUserNotifications = userNotifications.map((notif) =>
            notif.notification_id === notification_id ? { ...notif, is_read: true } : notif
        );

        setUserNotifications(updatedUserNotifications);
        localStorage.setItem("userNotif", JSON.stringify(updatedUserNotifications));

        // Update notification count
        const unreadCount = updatedUserNotifications.filter((notif) => !notif.is_read).length;
        setNotificationCount(unreadCount);

        // Optionally, mark it as read on the server as well
        axios.put(markNotificationAsReadURL, { notification_id }, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } })
            .catch((error) => console.error("Error marking notification as read:", error));
    };

    return (
        <NotificationContext.Provider
            value={{
                userNotifications,
                generalNotifications,
                notificationCount,
                hasUnreadGeneralNotifications,
                markNotificationAsRead,
                loading,
                setNotificationCount,  // Ensure setNotificationCount is part of the context
                setHasUnreadGeneralNotifications,  // Ensure setHasUnreadGeneralNotifications is part of the context
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => useContext(NotificationContext);

export default NotificationContextProvider;
