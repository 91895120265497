const Recent_product = (state, action) => {
    if (action.type === "ADD_RECENT") {
        let existing_product = state.RecentProduct?.find((item) => item?._id === action?.payload?._id);
        if (existing_product) {
            return state; // Return the same state if the product is already in the list
        } else {
            return { ...state, RecentProduct: [...state?.RecentProduct, action?.payload] }; // Add the new product to the list
        }
    }
    return state; // Ensure the reducer returns the state by default
};

export default Recent_product;
