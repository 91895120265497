import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactStars from 'react-stars';
import { IoCartOutline } from "react-icons/io5";
import { useCartContext } from '../../context';
import Modal from '../Modal/CategoryModal';

const ProductDetail = () => {
  const data = JSON.parse(localStorage.getItem("products")) || [];
  const { id } = useParams();
  const filterdata = data.filter((i) => i._id === id);
  const { addToCart, state } = useCartContext();
  const { Cart = [] } = state || {}; 
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [productReviews, setProductReviews] = useState([]);
  const [navigateToCart, setNavigateToCart] = useState(false); // State to trigger navigation
  const navigate = useNavigate();

  // Fetch reviews from local storage or state on component mount
  useEffect(() => {
    const allReviews = JSON.parse(localStorage.getItem("reviews")) || [];
    const currentProductReviews = allReviews.filter(review => review.SKU_Id === filterdata[0]?.SKU_id);
    setProductReviews(currentProductReviews);
  }, [id]);

  // Calculate average rating
  const averageRating = productReviews.length > 0 
    ? productReviews.reduce((acc, review) => acc + review.user_rating, 0) / productReviews.length 
    : 0;

    useEffect(() => {
      if (navigateToCart) {
        console.log("Redirecting to cart...");
        setTimeout(() => {
          navigate('/cart');
        }, 100); // Give some time for the cart state to update
      }
    }, [navigateToCart, Cart]);    

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handleAddToCart = (product) => {
    console.log("Adding product to cart:", product); // Log the product being added to cart
    addToCart(product);
  };

  const handleBuyNow = (product) => {
    console.log("Attempting to buy now:", product); // Log the product being attempted to buy

    // Ensure Cart is not undefined and handle it gracefully
    if (!Cart) {
      console.log("Cart is undefined, cannot proceed with Buy Now.");
      return;
    }

    // Check if the product is already in the cart
    const existingItem = Cart.find(cartItem => cartItem._id === product._id);
    console.log("Existing cart item check:", existingItem); // Log existing item check

    // If it's not in the cart, add it
    if (!existingItem) {
      console.log("Product not in cart. Adding to cart.");
      addToCart(product);
    }

    // Set the navigateToCart flag to true after adding to cart
    setNavigateToCart(true);
  };

  return (
    <>
      {filterdata.map((product) => (
        <div key={product._id} className="container mx-auto my-8 p-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Product Images */}
            <div className="flex flex-col items-center">
              <div className="w-full md:w-[500px] max-w-full h-auto mb-4">
                {product.image.length > 0 && (
                  <img
                    src={product.image[currentImageIndex]}
                    className="w-full object-cover rounded-lg shadow-md"
                    alt={product.name}
                  />
                )}
              </div>

              {/* Thumbnail Images */}
              <div className="flex space-x-3 overflow-x-auto">
                {product.image.map((img, index) => (
                  <div
                    key={index}
                    className="h-20 w-20 border rounded-md cursor-pointer hover:opacity-75 transition-opacity duration-200"
                    onClick={() => handleImageClick(index)}
                  >
                    <img src={img} alt={`${product.name} ${index + 1}`} className="h-full w-full object-cover rounded-md" />
                  </div>
                ))}
              </div>

              {/* Add to Cart and Buy Now Buttons */}
              <div className="grid grid-cols-2 gap-4 mt-6 w-full">
                <button
                  className="bg-yellow-500 w-full py-3 text-lg font-semibold text-white hover:bg-yellow-600 rounded-lg transition duration-200"
                  onClick={() => handleAddToCart(product)}
                >
                  <IoCartOutline className="inline mr-2" /> Add to Cart
                </button>
                <button
                  className="bg-orange-500 w-full py-3 text-lg font-semibold text-white hover:bg-orange-600 rounded-lg transition duration-200"
                  onClick={() => handleBuyNow(product)}  // Updated to handleBuyNow
                >
                  Buy Now
                </button>
              </div>
            </div>

            {/* Product Details */}
            <div className="space-y-4 w-full">
              <div className="border p-4 rounded-lg shadow-lg">
                <h1 className="text-3xl font-bold text-gray-800">{product.name}</h1>
                <div className="flex items-center space-x-3 mt-2">
                  <p className="text-xl text-gray-700 font-semibold">
                    ₹{product.selling_price || "Price not available"}
                  </p>
                  {product.MRP_price && (
                    <span className="text-green-600 font-medium text-sm">
                      (MRP: ₹{product.MRP_price})
                    </span>
                  )}
                </div>
                {product.discounted && (
                  <p className="text-sm text-red-600 line-through">Discounted Price: ₹{product.discounted}</p>
                )}
                
                {/* Average Rating Display */}
                <div className="flex items-center mt-2">
                  <ReactStars count={5} value={averageRating} size={24} color={'#ffd700'} edit={false} />
                  <span className="ml-2 text-gray-600">
                    {productReviews.length > 0 ? `${productReviews.length} reviews` : 'No reviews yet'}
                  </span>
                </div>
              </div>

              {/* Description */}
              <div className="border p-4 rounded-lg shadow-lg">
                <h2 className="font-bold text-xl">Description</h2>
                <p className="text-gray-700 mt-2">{product.description || "No description available."}</p>
              </div>

              {/* Show More Details Button */}
              <button
                className="text-blue-500 mt-4 focus:outline-none"
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails ? 'Hide Details' : 'Show More Details'}
              </button>

              {/* Additional Product Details (toggleable) */}
              {showDetails && (
                <div className="border p-4 rounded-lg shadow-lg mt-4">
                  <h2 className="font-bold text-2xl mb-4">Product Details</h2>
                  {Object.entries(product).map(([key, value]) => {
                    if (value && !["Links", "_id", "user_id", "image", "createdAt", "updatedAt", "description", "listing", "IN_stock"].includes(key)) {
                      const label = key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
                      const formattedValue = (key === "MRP_price" || key === "selling_price") ? `₹${value}` : value;
                    
                      return (
                        <div key={key} className="flex justify-between mb-2">
                          <span className="font-semibold">{label}:</span>
                          <span className="text-gray-700">{formattedValue}</span>
                        </div>
                      );
                    }
                    
                    return null;
                  })}
                </div>
              )}

              {/* Customer Reviews Section */}
              <div className="border p-4 rounded-lg shadow-lg mt-4">
                <h2 className="font-bold text-xl mb-4">Customer Reviews</h2>
                <div className="max-h-60 overflow-y-auto space-y-4">
                  {productReviews.length > 0 ? (
                    productReviews.map((review, index) => (
                      <div key={index} className="border-b pb-3 mb-3">
                        <div className="flex items-center">
                          <span className="font-semibold">{review.user_name}:</span>
                          <ReactStars
                            count={5}
                            value={review.user_rating}
                            size={20}
                            color={'#ffd700'}
                            edit={false}
                          />
                        </div>
                        <p className="text-gray-600">{review.user_review}</p>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">No reviews yet.</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Modal for Links */}
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} links={product.Links} />
        </div>
      ))}
    </>
  );
};

export default ProductDetail;
