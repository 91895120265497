import React from 'react';
 
const AboutUs = () => {
return(
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f0f0',
        padding: '20px'
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          padding: '40px',
          maxWidth: '800px',
          width: '100%'
        }}
      >
        <h1
          style={{
            textAlign: 'center',
            marginBottom: '30px',
            color: '#333',
            fontSize: '48px',
            fontWeight: 'bold'
          }}
        >
          ABOUT US
          </h1>
          <p>Welcome to <strong>DigiUncle </strong> (a ESPS SOLUTIONS PRIVATE LIMITED product), where we believe that every home tells a story. Our passion for home decor stems from
            the idea that a beautifully curated space can transform your everyday life into something extraordinary.
            Whether it's the warmth of a decorative candle, the charm of a handcrafted wooden tray, or the elegance of our
            exquisite wall hangings, we’re here to help you create a haven that reflects your unique personality and style.
            </p>
       <br/>
        <p>At <strong>DigiUncle</strong>, we carefully select and craft an array of home decor products that blend artistry with functionality.
         Our collection features:</p>
 
         <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginTop: '15px' }}>
            <li><strong>Wall Hanging Decors: </strong>From vibrant tapestries to minimalist art pieces, our wall hangings
            add character and depth to any room.</li>
            <li><strong>Wooden Trays: </strong>Perfect for serving or styling, our artisanal trays are designed to bring a rustic touch to
                your dining and living spaces.</li>
            <li><strong>Coasters: </strong>Protect your surfaces in style with our beautiful coasters, crafted from sustainable materials
                and available in various designs.</li>
            <li><strong>Iron Baskets: </strong>These versatile storage solutions not only keep your space organized but also add an industrial
                edge to your decor.</li>
            <li><strong>Decorative Candles and Diyas: </strong>Illuminate your home with our collection of candles and diyas, designed to
                create a cozy and inviting atmosphere.</li>
            <li><strong>Containers of All Kinds: </strong>From storage jars to decorative bowls, our containers are perfect for showcasing
                your favorite items while keeping your space clutter-free.</li>    
          </ul><br/>
 
          <p>Our commitment to quality and design ensures that each piece we offer not only enhances your home but also
            brings joy and inspiration to your everyday life. We believe in supporting artisans and using sustainable
            practices to bring you products that are not only beautiful but also ethically made.</p><br/>
 
            <p>Join us on this journey to celebrate the beauty of home decor. Explore our collection and discover the
                perfect pieces to elevate your living spaces. At DigiUncle, your home is our inspiration.</p>
    </div>
    </div>
)
}
export default AboutUs;
 