import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getReviewURL } from "../../api/api";

export const ReviewContext = createContext();

const ReviewContextProvider = ({ children }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Function to fetch reviews from API
    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await axios.get(getReviewURL);
                if (response.data && response.data.data) {
                    setReviews(response.data.data);
                    localStorage.setItem('reviews', JSON.stringify(response.data.data));
                } else {
                    throw new Error("Invalid response format");
                }
            } catch (error) {
                setError("Error fetching reviews");
                console.error("Error fetching reviews:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchReviews();
    }, []);

    return (
        <ReviewContext.Provider value={{
            reviews,
            loading,
            error
        }}>
            {children}
        </ReviewContext.Provider>
    );
};

export default ReviewContextProvider;
