import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosSearch } from "react-icons/io";

const SearchBar = () => {
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Function to fetch products based on search input
    const getData = async (value) => {
        try {
            setIsLoading(true);
            const result = JSON.parse(localStorage.getItem("products")) || [];
            const filteredResult = result.filter((item) =>
                value && item && item.name && 
                item.name.toLowerCase().includes(value.toLowerCase())
            );
            setData(filteredResult);
        } catch (error) {
            console.error("Error fetching search data:", error.message);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle search input change
    const handleSearch = (value) => {
        setSearch(value);
        if (value.trim() !== "") {
            getData(value);
        } else {
            setData([]);
        }
    };

    return (
        <div className='relative'>
            {/* Search input box */}
            <div className="flex items-center relative">
                <input 
                    type="text" 
                    placeholder="Search product" 
                    className="w-full md:w-[250px] lg:w-[400px] px-4 h-10 bg-blue-100 rounded-full focus:outline-none py-1 text-sm" 
                    value={search} 
                    onChange={(e) => handleSearch(e.target.value)} 
                />
                <IoIosSearch size={20} className="absolute right-3" />
            </div>

            {/* Search results dropdown */}
            <div 
                className={`absolute ${search === "" || data.length === 0 ? "hidden" : "block"} top-12 max-h-[60vh] overflow-y-auto w-full bg-white shadow-lg z-[9999] rounded-lg`}
            >
                {isLoading ? (
                    <div className="py-2 px-5 text-center">Loading...</div>
                ) : (
                    <ul>
                        {data.map((item) => (
                            <li 
                                key={item._id} 
                                className="hover:bg-blue-100 py-2 px-5 cursor-pointer flex items-center"
                            >
                                <Link 
                                    to={`/product/${item._id}`}  
                                    onClick={() => setSearch("")}
                                    className="flex items-center"
                                >
                                    <IoIosSearch size={20} className="mr-2" />
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SearchBar;
