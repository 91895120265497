import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useAuthContext } from '../context';
import toast from 'react-hot-toast';
 
// Modal Component for Forgot Password
const ForgotPasswordModal = ({ isVisible, onClose}) => {
    const{OTPset}=useAuthContext();
    const [email, setEmail] = useState('');
    const navigate = useNavigate(); // Correct usage of navigate
    const handleSendOTP = async () => {
        try {
            const result = await OTPset(email); // Call the OTP function
            if (result) {
                toast.success("OTP sent to email");
                onClose(); // Close the modal after successful OTP request
                navigate('/forgot-otp', { state: { email, purpose:'passwordChange' } }); // Pass the email to the /verify-otp page
            }
        } catch (error) {
            toast.error("Failed to send OTP");
        }
    };
 
    if (!isVisible) return null;
 
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
                <h2 className="text-lg font-bold mb-4">Forgot Password</h2>
                <input
                    type="email"
                    className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none mb-4"
                    placeholder="Enter your registered email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="flex justify-end space-x-3">
                    <button className="bg-gray-400 text-white px-4 py-2 rounded" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={handleSendOTP}>
                        Send OTP
                    </button>
                </div>
            </div>
        </div>
    );
};
 
const Login = () => {
    const { loginHandler, token, loading, forgotPassword } = useAuthContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false); // For password visibility toggle
    const [showModal, setShowModal] = useState(false); // For showing the forgot password modal
    const [loginCredential, setLoginCredentials] = useState({
        email: "",
        password: "",
    });
 
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form behavior
        loginHandler(loginCredential); // Call login handler
    };
 
    useEffect(() => {
        if (token) {
            window.location.href = location?.state?.from?.pathname ?? "/";
        }
    }, [token, location]);
 
    return (
        <div
            className="h-[84vh] sm:h-[85vh] bg-no-repeat bg-cover bg-center"
            style={{
                backgroundImage: "url('https://images.unsplash.com/photo-1486520299386-6d106b22014b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80')",
            }}
        >
            <div className="flex justify-end">
                <div className="bg-white h-[84vh] sm:h-[85vh] w-full sm:w-1/2 flex justify-center items-center">
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <h1 className="text-2xl font-bold">Login Here</h1>
                            </div>
                            <div className="my-3">
                                <label className="block text-md mb-2" htmlFor="email">Email</label>
                                <input
                                    className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
                                    type="email"
                                    name="email"
                                    placeholder="email"
                                    onChange={(e) =>
                                        setLoginCredentials({
                                            ...loginCredential,
                                            email: e.target.value,
                                        })
                                    }
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    required
                                />
                            </div>
                            <div className="mt-5 relative">
                                <label className="block text-md mb-2" htmlFor="password">Password</label>
                                <input
                                    className="px-4 w-full border-2 py-2 rounded-md text-sm outline-none"
                                    type={show ? "text" : "password"}
                                    name="password"
                                    placeholder="password"
                                    onChange={(e) =>
                                        setLoginCredentials({
                                            ...loginCredential,
                                            password: e.target.value,
                                        })
                                    }
                                    required
                                />
                                {show ? (
                                    <IoEyeOffOutline
                                        className="absolute top-[60%] right-2 cursor-pointer"
                                        onClick={() => setShow(false)}
                                    />
                                ) : (
                                    <IoEyeOutline
                                        className="absolute top-[60%] right-2 cursor-pointer"
                                        onClick={() => setShow(true)}
                                    />
                                )}
                            </div>
                            <div className="mt-3">
                                <span className="cursor-pointer text-md text-blue-600" onClick={() => setShowModal(true)}>
                                    Forgot your password?
                                </span><br />
                                <span className="cursor-pointer text-sm text-blue-600">
                                    Need help signing in?
                                </span>
                            </div>
                            <div className="">
                                <button
                                    className="mt-4 mb-3 w-full bg-green-500 hover:bg-green-400 text-white py-2 rounded-md flex items-center justify-center gap-3"
                                    type="submit"
                                >
                                    {loading ? (
                                        <img src="./loader/loader.gif" alt="Loading..." />
                                    ) : null}
                                    Login
                                </button>
                            </div>
                        </form>
                        <p className="mt-8">
                            Don't have an account?{" "}
                            <span
                                className="cursor-pointer text-sm text-blue-600"
                                onClick={() => navigate("/signup")}
                            >
                                Sign up
                            </span>
                        </p>
                    </div>
                </div>
            </div>
 
            {/* Forgot Password Modal */}
            <ForgotPasswordModal
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                sendOTP={forgotPassword} // Pass the forgot password function
            />
        </div>
    );
};
 
export default Login;
 
 