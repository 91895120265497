import React, { useState, useEffect } from 'react';
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context';
import OTPInput, { ResendOTP } from 'otp-input-react';
 
const VerifyOtp = ({ handleBackToLogin }) => {
  const { OTPset, verifyOtp } = useAuthContext();
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
 
  // Retrieve email from location state, fallback to localStorage if not present
  const email = location?.state?.email || JSON.parse(localStorage.getItem('userData'))?.email;
  const emailVerification = location?.state?.email;
  const purpose = location?.state?.purpose;
  const pf = JSON.parse(localStorage.getItem('userData')) || {};
  const fromProfile = location?.state?.fromProfile;
 
  useEffect(() => {
    if (!email) {
      toast.error('Email is required');
      navigate('/login'); // Navigate back to login page if no email
    }
  }, [email, navigate]);
 
  // Handle OTP sending
  const handleGetOTP = async () => {
    try {
      if (!email) {
        toast.error('No email provided');
        return;
      }
 
      const result = await OTPset(email);
      if (result) {
        toast.success("OTP sent to email");
      }
    } catch (error) {
      console.error("Error in handleGetOTP:", error);
      toast.error("An error occurred while sending OTP");
    }
  };
 
  // Handle OTP verification
  const handleVerifyOTP = async () => {
    if (!otp) {
      toast.error('OTP is required');
      return;
    }
    try {
      const result = await verifyOtp(otp, email, purpose); // Use the correct email here
      if (result) {
        if (!emailVerification) {
          navigate('/reset-password', { state: { email } }); // Navigate to reset password page after OTP verification
        } else {
          if (fromProfile) {
            navigate('/profile'); // Navigate to profile page if verification was initiated from profile
          } else {
            navigate('/checkout'); // Navigate to checkout page if verification was initiated from cart
          }
        }
        toast.success('Verification successful');
        pf.isEmailVerified = true;
        localStorage.setItem('userData', JSON.stringify(pf));
      }
    } catch (error) {
      console.error("Error in verifying OTP:", error);
      toast.error('Failed to verify OTP');
    }
  };
 
  return (
    <div className="container mx-auto p-4 flex justify-center items-center">
      <div className="bg-blue-50 shadow-lg rounded-lg p-6 w-full sm:w-3/4 md:w-2/3 lg:w-1/2">
        <h3 className="text-3xl text-black font-semibold mb-6 text-center">Verify Your OTP</h3>
        <div className="text-lg text-gray-700 mb-4 text-center">Enter the OTP sent to your email</div>
       
        <div className="flex justify-center mb-4">
          <OTPInput
            className="otpnum"
            value={otp}
            OTPLength={6}
            onChange={(otp) => setOtp(otp)}
          />
        </div>
 
        <div className="flex justify-center mb-6">
          <ResendOTP otpType="number" onResendClick={handleGetOTP} className="text-blue-500 border border-blue-700 rounded px-6 py-3 font-semibold cursor-pointer hover:bg-blue-500 hover:text-white transition-all" />
        </div>
 
        <div className="text-center">
          <button
            className="bg-blue-500 text-white rounded px-8 py-3 text-xl font-semibold w-full hover:bg-blue-600 transition-all"
            onClick={handleVerifyOTP}
          >
            Verify OTP
          </button>
        </div>
      </div>
    </div>
  );
};
 
export default VerifyOtp;
 
 