import React from 'react';
import { Banner, ProductCard } from '../components';
import { Link } from 'react-router-dom';
import { useProductContext } from '../context';
 
const Home = () => {
    const { RecentProduct, loading } = useProductContext();
    const data = JSON.parse(localStorage.getItem("products")) || [];
    const data1 = data.slice(0, 10);
 
    if (loading) {
        return <div>Loading...</div>;
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
 
    return (
        <>
         <div className="gap-0 py-4 relative">
            {/* Set z-index to keep the banner under the dropdown */}
            <div className="relative z-10">
                <Banner />
            </div>
 
            {/* Recently Viewed Section */}
            {RecentProduct?.length > 0 && (
                <div className="py-5">
                    <h2
                        className="
                            mx-auto
                            bg-blue-200
                            text-center
                            rounded-full
                            font-serif font-semibold
                            text-2xl sm:text-3xl md:text-4xl
                            h-16 sm:h-20 md:h-24
                            w-[80%] sm:w-[70%] md:w-[50%] lg:w-[25%]
                            flex items-center justify-center
                            leading-none"
                    >
                        Recently Viewed
                    </h2>
                    <div className="px-4">
                        <ProductCard data={RecentProduct} />
                    </div>
                </div>
            )}
 
            {/* Best Selling Section */}
            <div className={`py-${RecentProduct?.length > 0 ? '3' : '5'} md:py-3`}>
                <h2
                    className="
                        mx-auto
                        bg-blue-200
                        text-center
                        rounded-full
                        font-serif font-semibold
                        text-2xl sm:text-3xl md:text-4xl
                        h-16 sm:h-20 md:h-24
                        w-[80%] sm:w-[70%] md:w-[50%] lg:w-[25%]
                        flex items-center justify-center
                        leading-none"
                >
                    Best Selling
                </h2>
                <div className="px-4">
                    {data.length > 0 ? (
                        <ProductCard data={data1} />
                    ) : (
                        <p>No products available</p>
                    )}
                </div>
                <Link to="/productlist"onClick={scrollToTop} className="py-2 flex w-full justify-end items-center px-5">
                    <button className="bg-orange-500 text-white text-lg px-4 py-2 rounded-md hover:bg-orange-600 transition duration-300">
                       Explore More...
                    </button>
                </Link>
            </div>
         </div>
        </>
    );
};
 
export default Home;
 
 
 