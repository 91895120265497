import React, { useState } from 'react';
import axios from 'axios'; // Ensure axios is imported
import { FaRegUser, FaRegEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // For navigation
import toast from "react-hot-toast";
import { userUpdateURL } from '../api/api';
import { useAuthContext } from '../context';
 
const Profile = () => {
  const navigate = useNavigate();
  const { OTPset } = useAuthContext();
 
  const pf = JSON.parse(localStorage.getItem('userData')) || {};
 
  const [data, setData] = useState({
    firstName: pf.firstName || '',
    lastName: pf.lastName || '',
    email: pf.email || '',
    mobile: pf.mobile || '',
  });
  const [editMode, setEditMode] = useState(false);
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        userUpdateURL,
        { ...data, id: pf.user_id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include your auth token if needed
          },
        }
      );
      alert(response.data.message);
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      setEditMode(false);
    } catch (error) {
      console.error('Error updating user:', error);
      alert(`An error occurred: ${error.message}`);
    }
  };
 
  const handleOTP = async () => {
    console.log("handleOTP called"); // Debug log to confirm execution
    try {
      console.log("inside try");
      const result = await OTPset(pf.email);
      console.log(result); // Call the context function
      if (result) {
        // Handle success
        toast.success("OTP sent to email");
        console.log("Navigating to /verify-otp...");
        navigate('/verify-otp',{ state: { purpose:'passwordChange'} }); // Navigate only on success
      }
    } catch (error) {
      // Handle error cases
      console.error("Error in handleOTP:", error);
      toast.error("An error occurred while sending OTP");
    }
  };
 
 const handleEmail = async()=>{
  const email = pf.email || ''; // Get email from user data or leave empty
 
  if (!email) {
    toast.error("Email is required to proceed.");
    return; // Stop execution if email is missing
  }
 
  if (!pf.isEmailVerified) {
    try {
      const result = await OTPset(email); // Call the OTP function
      if (result) {
        toast.success("OTP sent to email");
        navigate('/verify-otp', { state: { email, purpose:'emailVerification', fromProfile: true } }); // Pass the email to the /verify-otp page
        return; // Exit function after successful OTP send
      }
    } catch (error) {
      toast.error("Failed to send OTP");
      return; // Exit function in case of an error
    }
  } else {
    // If email is verified, proceed to checkout
    navigate("/profile");
    toast.success("Email Verification Successful");
  }
 }
 
 
  return (
    <div className="h-fit flex items-center justify-center py-12">
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col md:flex-row">
          {/* Profile Picture and Status */}
          <div className="w-full md:w-1/4 flex flex-col items-center p-5 border-b md:border-b-0 md:border-r md:border-r-2">
            <div className="w-24 h-24 bg-gray-300 rounded-full mb-4 flex items-center justify-center">
              <FaRegUser className="text-4xl text-gray-600" />
            </div>
            <h1 className="text-xl font-semibold">{data.firstName} {data.lastName}</h1>
            <span className="bg-green-500 text-white text-sm px-3 py-1 rounded mt-2">Active</span>
          </div>
 
          {/* Profile Details */}
          <div className="w-full md:w-3/4 p-5">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Your Profile</h2>
              <button
                className="text-blue-500"
                onClick={() => setEditMode(!editMode)}
              >
                <FaRegEdit size={20} />
              </button>
            </div>
 
            {editMode ? (
              <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block font-semibold">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-semibold">Contact No.</label>
                  <input
                    type="text"
                    name="mobile"
                    value={data.mobile}
                    onChange={handleChange}
                    className="border rounded px-3 py-2 w-full"
                  />
                </div>
                <div className="col-span-2 text-right">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                <div>
                  <label className="font-semibold">First Name:</label>
                  <p className="text-gray-700">{data.firstName}</p>
                </div>
                <div>
                  <label className="font-semibold">Last Name:</label>
                  <p className="text-gray-700">{data.lastName}</p>
                </div>
                <div>
                  <label className="font-semibold">Email:</label>
                  <p className="text-gray-700">{data.email}</p>
                </div>
                <div>
                  <label className="font-semibold">Contact No.:</label>
                  <p className="text-gray-700">{data.mobile}</p>
                </div>
              </div>
            )}
          </div>
        </div>
        {pf.isEmailVerified && (
        <button onClick={handleOTP}
            className="bg-yellow-600 ptext-white text-sm px-5 py-2 ml-20 rounded-md hover:bg-yellow-700 transition duration-300"
           >
            Reset Password
         </button>
        )}
         {!pf.isEmailVerified && (
          <div className='flex gap-2'>
            <button onClick={handleEmail}
              className="bg-blue-500 text-white text-sm px-5 py-2 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Verify Your Email
            </button>
             <button onClick={handleOTP}
             className="bg-yellow-600 ptext-white text-sm px-5 py-2 rounded-md hover:bg-yellow-700 transition duration-300"
            >
             Reset Password
          </button></div>
          )}
      </div>
    </div>
  );
};
 
export default Profile;
 
 
 