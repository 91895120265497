import { Cart, CheckOut, Orders, VerifyOtp, AdminPanel } from "../pages";
import Profile from "../pages/Profile";
 
 
 
export const privateRoutes = [
    {
        path : "/Cart",
        element : <Cart />
    },
    {
        path : "/profile",
        element : <Profile />
    },
    {
        path : "/checkout",
        element : <CheckOut />
    },
    {
        path : "/orders",
        element : <Orders />
    },
    {
        path : '/verify-otp',
        element : <VerifyOtp/>,
    },
    {
        path : '/admin-panel',
        element : <AdminPanel/>,
    },
]
 
 
 