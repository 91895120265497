import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // For navigation
import toast from 'react-hot-toast';
import { useAuthContext } from '../context';
 
const ResetPassword = () => {
  const { resetPassword } = useAuthContext();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordToggle, setPasswordToggle] = useState(false);
  const location = useLocation();
 
  // Retrieve email from location state, fallback to localStorage if not present
  const email = location?.state?.email || JSON.parse(localStorage.getItem('userData'))?.email;
  const forgotemail=location?.state?.email;
  const resetemail=  JSON.parse(localStorage.getItem('userData'))?.email;
 
  const handlePasswordToggle = () => {
    setPasswordToggle(!passwordToggle);
  };
 
  const handleSubmit = async (e) => {
    e.preventDefault();
 
    if (!newPassword || !confirmPassword) {
      toast.error('Both fields are required');
      return;
    }
 
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
 
    try {
      // Call the resetPassword function and pass the email and new password
      const result = await resetPassword(email, newPassword);
      if (result) {
        toast.success("Password Reset Successfully");
        if(resetemail){
        navigate('/profile'); // Navigate to profile after successful reset
        }
        else{
            navigate('/login');  
        }
      }
    } catch (error) {
      console.error('Error resetting password:', error);
     }
  };
 
  return (
    <div className="h-fit flex items-center justify-center py-12">
      <div className="container mx-auto p-6 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col md:flex-row">
          {/* Password Reset Form */}
          <div className="w-full md:w-3/4 p-5">
            <h2 className="text-xl font-semibold mb-4">Reset Your Password</h2>
 
            <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block font-semibold">New Password</label>
                <input
                  type={passwordToggle ? "text" : "password"}
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="border rounded px-3 py-2 w-full"
                  placeholder="Enter new password"
                  required
                />
              </div>
              <div>
                <label className="block font-semibold">Confirm New Password</label>
                <input
                  type={passwordToggle ? "text" : "password"}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="border rounded px-3 py-2 w-full"
                  placeholder="Confirm new password"
                  required
                />
                <div className="form-group mt-2 flex items-center gap-2">
                  <input
                    className="border"
                    type="checkbox"
                    checked={passwordToggle}
                    onChange={handlePasswordToggle}
                    id="toggleConfirmPassword"
                  />
                  <label htmlFor="toggleConfirmPassword">Show password</label>
                </div>
              </div>
 
              <div className="col-span-2 text-right">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default ResetPassword;
 
 
 