import React, { useState, useEffect } from 'react';
import { FaPlus, FaRupeeSign } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import toast from 'react-hot-toast';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useCartContext, useAuthContext } from '../context';
import { createAddressURL } from '../api/api';
import "../App.css";
import { useWishlistContext } from '../context'; // Adjust the path if needed
 
const Cart = () => {
  const navigate = useNavigate();
  const { Decrease, Increase, Cart, removeItem } = useCartContext();
  const{OTPset}=useAuthContext();
  const { addToWishlist } = useWishlistContext();
  const [promoCode, setPromoCode] = useState('');
  const [validPromo, setValidPromo] = useState(false);
  const [promoApplied, setPromoApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const promoCodes = JSON.parse(localStorage.getItem("promoCodes") || "[]");
  const [email, setEmail] = useState('');
 
  const handleAddToWishlist = (item) => {
    addToWishlist(item); // Call addToWishlist function to add the item
  };
  const pf = JSON.parse(localStorage.getItem('userData')) || {};
 
  const handleProceedToCheckout = async () => {
    const email = pf.email || ''; // Get email from user data or leave empty
 
    if (!email) {
      toast.error("Email is required to proceed.");
      return; // Stop execution if email is missing
    }
 
    if (!pf.isEmailVerified) {
      try {
        const result = await OTPset(email); // Call the OTP function
        if (result) {
          toast.success("OTP sent to email");
          navigate('/verify-otp', { state: { email, purpose:'emailVerification' } }); // Pass the email to the /verify-otp page
          return; // Exit function after successful OTP send
        }
      } catch (error) {
        toast.error("Failed to send OTP");
        return; // Exit function in case of an error
      }
    } else {
      // If email is verified, proceed to checkout
      navigate("/checkout");
    }
  };
 
 
  // Calculate initial total and total after discount
  const Price = Cart.reduce((total, item) => total + item.MRP_price * item.productcount, 0);
  const totalDiscountAmount = Cart.reduce((totalDiscount, item) => {
    const discount = (item.MRP_price - item.selling_price) * item.productcount;
    return totalDiscount + (discount > 0 ? discount : 0);
  }, 0);
 
  const totalAmountAfterDiscount = Price - totalDiscountAmount - discountAmount;
 
  useEffect(() => {
    localStorage.setItem("totalAmountAfterDiscount", JSON.stringify(totalAmountAfterDiscount));
    if (validPromo) {
      localStorage.setItem(
        "appliedPromo",
        JSON.stringify({
          code: promoCode,
          discountPercentage: discountAmount > 0 ? (discountAmount / (Price - totalDiscountAmount) * 100).toFixed(2) : 0,
        })
      );
    } else {
      localStorage.removeItem("appliedPromo");
    }
  }, [totalAmountAfterDiscount, promoCode, validPromo, discountAmount]);
 
  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
    setValidPromo(false);
    setDiscountAmount(0);
  };
 
  const handleApplyPromo = () => {
    setPromoApplied(true);
 
    if (!promoCode) {
      toast.error("Please select a promo code to apply.");
      return;
    }
 
    const selectedPromo = promoCodes.find((promo) => promo.code === promoCode);
    if (selectedPromo) {
      setValidPromo(true);
      const discount = (selectedPromo.discount / 100) * (Price - totalDiscountAmount);
      setDiscountAmount(discount);
      toast.success(`Promo code applied! You saved ₹${discount.toFixed(2)}`);
    } else {
      setValidPromo(false);
      setDiscountAmount(0);
      toast.error("Invalid Promo Code.");
    }
  };
 
  return (
    <>
      {Cart?.length > 0 ? (
        <div className="md:py-10 w-screen h-fit">
          <h1 className="text-4xl font-extrabold text-center text-gray-800">Your Cart</h1>
            {/* Cart Items */}
          <div className="flex flex-wrap md:py-10 w-screen md:w-[140vw]">
          <div className="w-full md:w-2/5 px-4 h-fit overflow-y-auto rounded-xl md:mx-8">
            <div className="bg-gray-50 shadow-md rounded-lg mb-5 py-3 px-5 flex justify-between items-center">
              <span className="text-sm font-medium">Total Unique Items</span>
              <button className="px-3 py-1 border rounded text-sm">{Cart?.length}</button>
            </div>
              {Cart?.map((item) => (
                <div key={item._id} className="bg-white shadow-sm rounded-lg mb-4 p-4 flex gap-4 md:pl-12">
                  {/* Item Details */}
                  <div className="flex flex-col items-center gap-4">
                    <img src={item.image[0]} alt="product" className="h-28 w-28 rounded-md object-cover" />
                    <div className="flex items-center gap-2 border rounded-full px-2 py-1">
                      <p>Qty: </p>
                      <button onClick={() => Decrease(item)} className="text-lg"><FaMinus /></button>
                      <span className="px-2">{item.productcount}</span>
                      <button onClick={() => Increase(item)} className="text-lg"><FaPlus /></button>
                    </div>
                  </div>
 
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold">{item.name}</h3>
                    <p className="text-gray-600 text-sm">Color: {item.product_color}</p>
                    <p className="text-gray-600 text-sm">Seller: {item.brand}</p>
                   
                   
                    <div className="mt-2">
                      <span className="line-through text-gray-500 mr-2">{`₹${item.MRP_price}`}</span>
                      <span className="text-lg font-bold text-black">{`₹${item.selling_price}`}</span>
                      <span className="ml-2 text-green-600 text-sm">
                        {((item.MRP_price - item.selling_price) / item.MRP_price * 100).toFixed(0)}% Off
                      </span>
                    </div> </div>
                    <div className="mt-3 pr-4 flex items-center gap-4">
                      <button
                        className="text-sm text-green-600 font-medium"
                        onClick={() => handleAddToWishlist(item)}
                      >
                        Add to Wishlist
                      </button>
                      <button
                        onClick={() => { removeItem(item)}}
                        className="text-sm text-red-500 font-medium"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
              ))}
            </div>
 
            {/* Price Details */}
            <div className="w-full md:w-1/4 px-4">
              <div className="bg-white shadow-md rounded-lg p-5">
                <h2 className="text-xl font-bold mb-4 text-gray-800">Price Details</h2>
 
                <div className="flex justify-between mb-3">
                  <span className="text-base text-gray-600">Price ({Cart?.length} items)</span>
                  <span className="text-base font-semibold text-gray-900">₹{Price.toFixed(2)}</span>
                </div>
 
                <div className="flex justify-between mb-3">
                  <span className="text-base text-gray-600">Discount</span>
                  <span className="text-base font-semibold text-green-600">-₹{totalDiscountAmount.toFixed(2)}</span>
                </div>
 
                <div className="my-3">
                  <label htmlFor="promoCode" className="block text-gray-700 text-sm font-semibold mb-2">
                    Apply Promo Code
                  </label>
                  <div className="flex gap-2">
                    {promoCodes.length > 0 ? (
                      <>
                        <select
                          id="promoCode"
                          value={promoCode}
                          onChange={handlePromoCodeChange}
                          className="border rounded-lg p-1 w-full text-gray-700"
                        >
                          <option value="" disabled>Select Promo Code</option>
                          {promoCodes.map((promo) => (
                            <option key={promo.code} value={promo.code}>
                              {promo.code}
                            </option>
                          ))}
                        </select>
                        <button
                          className="bg-blue-600 text-white px-4 rounded-lg"
                          onClick={handleApplyPromo}
                          disabled={!promoCode} // Disable if no code is selected
                        >
                          Apply
                        </button>
                      </>
                    ) : (
                      // Display this message if no promo codes are available
                      <p className="text-gray-500">No promo codes available</p>
                    )}
                  </div>
 
                  {/* Show feedback only after Apply is clicked */}
                  {promoApplied && validPromo && (
                    <p className="text-green-600 mt-2">
                      Promo code applied successfully! You’re getting a {promoCodes.find(promo => promo.code === promoCode)?.discount}% discount.
                    </p>
                  )}
                  {promoApplied && !validPromo && (
                    <p className="text-red-600 mt-2">Invalid Promo Code. Please try again.</p>
                  )}
                </div>
 
                <hr className="my-3 border-gray-200" />
 
                <div className="flex justify-between mb-4 font-semibold text-gray-900">
                  <span className="text-lg">Total Amount</span>
                  <span className="text-lg">₹{totalAmountAfterDiscount.toFixed(2)}</span>
                </div>
 
                <p className="text-sm text-green-500 mb-4">
                  You will save ₹{(totalDiscountAmount + discountAmount).toFixed(2)} on this order.
                </p>
                <button
                className="w-full bg-blue-600 text-white py-3 rounded-md font-semibold hover:bg-blue-700 transition duration-300"
                onClick={handleProceedToCheckout} // Trigger modal check
              >
                Proceed to Checkout
              </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-16 sm:py-24">
          <div className="text-center my-8">
            <h1 className="text-4xl font-extrabold text-gray-800">Your Cart is Empty</h1>
          </div>
          <img
            src="./image/EmptyCart.gif"
            alt="Empty Cart"
            className="w-3/4 sm:w-1/2 mb-4"
            style={{ background: 'transparent' }}
          />
          <Link
            to="/"
            className="
              flex items-center justify-center
              text-black text-sm sm:text-base md:text-lg
              text-center hover:text-blue-900
              bg-yellow-500
              h-10 sm:h-12 md:h-14
              w-3/4 sm:w-1/3 md:w-1/6
              mx-auto
              rounded-md"
          >
            <b>SHOP NOW</b>
          </Link>
        </div>
      )}
    </>
  );
};
 
export default Cart;
 
 
 