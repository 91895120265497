// Banner.js
import React, { useEffect, useState } from 'react';
import "./banner.css";
import { bannerdata } from '../../database/db'; // Assuming this is a local import

const Banner = () => {
  const [data, setData] = useState(bannerdata[0]); // Load the first GIF data

  useEffect(() => {
    // Fetch logic if needed later
  }, []);

  return (
    <div className="banner-container">
  <img
    src={`${process.env.PUBLIC_URL}/${data.path}`}
    alt={data.description || "Banner GIF"}
    className="banner-gif"
  />
</div>
  );
};

export default Banner;
