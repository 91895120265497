import { createContext, useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { signUpURL, LoginURL, resetPasswordURL, OTPsetURL, verifyOtpURL } from "../../api/api";
 
export const AuthContext = createContext();
 
const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
 
  // Check for token in localStorage on initial load
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []); // Empty dependency array ensures this only runs once after mount

  const SignUp = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(signUpURL, data);
      const authToken = res.data.token;
      const authMessage = res.data.message;
      const userData = res.data.userData;
      const address = res.data.address;
      const orders = res.data.orders;
      const promoCodes = res.data.promoCodes;
      const generalNotif = res.data.generalNotif;
      const userNotif = res.data.userNotif;
      const wishlist = res.data.wishlist;
     
      if (authToken) {
        setToken(authToken);
        localStorage.setItem('token', authToken);
        localStorage.setItem('message', authMessage);
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('address', JSON.stringify(address));
        localStorage.setItem('orders', JSON.stringify(orders));
        localStorage.setItem('promoCodes', JSON.stringify(promoCodes));
        localStorage.setItem('generalNotif', JSON.stringify(generalNotif));
        localStorage.setItem('userNotif', JSON.stringify(userNotif));
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
      }
 
      toast.success(res.data.message);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred during sign-up");
    } finally {
      setLoading(false);
    }
  };
 
  const loginHandler = async (value) => {
    const data = {
      email: value.email,
      password: value.password
    };
 
    setLoading(true);
 
    try {
      const res = await axios.post(LoginURL, data);
      const authToken = res.data.token;
      const authMessage = res.data.message;
      const userData = res.data.userData;
      const address = res.data.address;
      const orders = res.data.orders;
      const promoCodes = res.data.promoCodes;
      const generalNotif = res.data.generalNotif;
      const userNotif = res.data.userNotif;
      const wishlist = res.data.wishlist;
      console.log(userNotif);
      if (authToken) {
        setToken(authToken);
        localStorage.setItem('token', authToken);
        localStorage.setItem('message', authMessage);
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('address', JSON.stringify(address));
        localStorage.setItem('orders', JSON.stringify(orders));
        localStorage.setItem('promoCodes', JSON.stringify(promoCodes));
        localStorage.setItem('generalNotif', JSON.stringify(generalNotif));
        localStorage.setItem('userNotif', JSON.stringify(userNotif));
        localStorage.setItem('wishlist', JSON.stringify(wishlist));
      }
 
      toast.success(res.data.message);
    } catch (err) {
      console.error("Login error response:", err.response);
      if (!err.response.data.errors) {
        toast.error(err.response.data.message);
      } else {
        if (err.response.data.errors.length > 1) {
          toast.error("Email or Password is required");
        } else {
          toast.error(err.response.data.errors[0].msg);
        }
      }
    } finally {
      setLoading(false);
    }
  };
 
  const logoutHandler = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will be logged out!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log me out!'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        localStorage.removeItem("message");
        localStorage.removeItem("userData");
        localStorage.removeItem('address');
        localStorage.removeItem('orders');
        localStorage.removeItem('promoCodes');
        localStorage.removeItem('selectedAddress');
        localStorage.removeItem('appliedPromo');
        localStorage.removeItem('Cart');
        localStorage.removeItem('generalNotif');
        localStorage.removeItem('userNotif');
        localStorage.removeItem('wishlist');
        localStorage.removeItem('adminOrders');
        setToken(null);
        window.location.href = '/login'; // Redirect to login page after logout
      }
    });
  };
  
  const OTPset = async (email) => {
    try {
      const response = await axios.patch(OTPsetURL, { email });
      if (response.status === 200) {
        return response.data; // Assuming your backend now returns a message like 'OTP sent to email'
      } else {
        throw new Error('Failed to send OTP');
      }
    } catch (error) {
      console.error("Error in forgot password:", error);
      throw error; // This allows the caller to handle the error as needed
    }
  };
 
  const resetPassword = async (email, newPassword) => {
    try {
      const response = await axios.post(resetPasswordURL, { email, password: newPassword });
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Failed to reset password");
        return null;
      }
    } catch (error) {
      console.error("Error in resetting password:", error);
      toast.error("An error occurred while resetting password");
      return null;
    }
  };
 
  const verifyOtp = async (otp, email, purpose) => {
    try {
      const response = await axios.post(verifyOtpURL, { otp, email, purpose });
      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Failed to verify OTP");
        return null;
      }
    } catch (error) {
      console.error("Error in verifying OTP:", error);
      toast.error('Incorrect OTP. Please try again');
      return null;
    }
  };
 
  return (
    <AuthContext.Provider
      value={{
        loginHandler,
        token,
        loading,
        logoutHandler,
        SignUp,
        resetPassword,
        verifyOtp,
        OTPset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
 
export default AuthContextProvider;
 