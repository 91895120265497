import React, { useEffect, useState, useRef } from "react";
import { AiOutlineNotification, AiOutlineTag } from "react-icons/ai";
import { IoCloseCircleSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import axios from "axios";
import { markNotificationAsReadURL } from "../api/api"; // Import the URL for marking as read
import { useAuthContext, useNotificationContext } from "../context";  // Use notification context

const Notification = ({ SetNotification, notification }) => {
  const [userNotifications, setUserNotifications] = useState([]);
  const [generalNotifications, setGeneralNotifications] = useState([]);
  const panelRef = useRef(null);
  const { token } = useAuthContext(); // Get token from auth context
  const { setNotificationCount, setHasUnreadGeneralNotifications } = useNotificationContext(); // Use notification context methods

  useEffect(() => {
      if (notification) {
          const userNotif = JSON.parse(localStorage.getItem("userNotif")) || [];
          const generalNotif = JSON.parse(localStorage.getItem("generalNotif")) || [];

          setUserNotifications(userNotif);
          setGeneralNotifications(generalNotif);
      }
  }, [notification]);

  const markNotificationAsRead = async (notification_id) => {
      try {
          const updatedNotifications = userNotifications.map((notif) =>
              notif.notification_id === notification_id ? { ...notif, is_read: true } : notif
          );

          setUserNotifications(updatedNotifications);
          localStorage.setItem("userNotif", JSON.stringify(updatedNotifications));

          // Update the notification count in the context to trigger re-renders
          const unreadUserCount = updatedNotifications.filter((notif) => !notif.is_read).length;
          setNotificationCount(unreadUserCount);  // This will trigger a re-render in the Navbar/Sidebar

          if (token) {
              await axios.put(
                  markNotificationAsReadURL,
                  { notification_id },  // Sending the notification_id in the body
                  {
                      headers: { Authorization: `Bearer ${token}` }
                  }
              );
          }

          // Update the unread general notifications status
          setHasUnreadGeneralNotifications(
              generalNotifications.some((notif) => !notif.is_read)
          );

      } catch (error) {
          console.error("Error marking notification as read:", error.response ? error.response.data : error.message);
      }
  };

  useEffect(() => {
      const handleClickOutside = (event) => {
          if (panelRef.current && !panelRef.current.contains(event.target)) {
              SetNotification(false); // Close the notification when clicked outside
          }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [SetNotification]);

  return (
      <div className={`fixed w-full h-full z-50 right-0 bg-black bg-opacity-30 ${notification ? "block" : "hidden"}`}>
          <div ref={panelRef} className="absolute right-0 h-full w-full md:w-1/3 bg-white shadow-lg p-6 overflow-y-auto">
              <div className="flex items-center justify-between mb-6 border-b pb-4">
                  <div className="flex items-center gap-2">
                      <AiOutlineNotification size={24} className="text-gray-800" />
                      <p className="text-xl font-semibold text-gray-800">Notifications</p>
                  </div>
                  <button onClick={() => SetNotification(false)} aria-label="Close notification sidebar">
                      <MdClose className="text-gray-500 hover:text-gray-700" size={32} />
                  </button>
              </div>

              {/* User-Specific Notifications */}
              <div className="mb-6">
                  {userNotifications.length > 0 ? (
                      userNotifications.map((notif) => (
                          <div
                              key={notif.notification_id}
                              className={`relative p-4 mb-4 rounded-lg shadow-md transition-opacity ${
                                  notif.is_read ? "bg-gray-100 opacity-75" : "bg-white"
                              } border-l-4 ${
                                  notif.is_read ? "border-green-400" : "border-blue-500"
                              }`}
                          >
                              <div className="flex items-start justify-between">
                                  <div className="w-full">
                                      <p className="text-gray-800 font-semibold flex items-center gap-1">
                                          <AiOutlineNotification size={18} className="text-blue-500" />
                                          {notif.title}
                                      </p>
                                      <p className="text-gray-600 text-sm mt-1">{notif.message}</p>
                                      <p className="text-gray-400 text-xs mt-2">
                                          {new Date(notif.created_at).toLocaleString()}
                                      </p>
                                  </div>
                                  <IoCloseCircleSharp
                                      onClick={() => markNotificationAsRead(notif.notification_id)}
                                      className="text-red-500 hover:text-red-600 cursor-pointer"
                                      size={24}
                                  />
                              </div>
                          </div>
                      ))
                  ) : (
                      <p className="text-gray-500 text-sm italic">No new notifications.</p>
                  )}
              </div>

              {/* General Notifications */}
              <div>
                  <p className="text-md font-medium text-gray-700 border-b pb-2 mb-4 flex items-center gap-1">
                      <AiOutlineTag size={18} className="text-yellow-500" /> Offers/Sale Updates
                  </p>
                  {generalNotifications.length > 0 ? (
                      generalNotifications.map((notif) => (
                          <div
                              key={notif.notification_id}
                              className="p-4 mb-4 rounded-lg shadow-md bg-gray-50 border-l-4 border-yellow-400"
                          >
                              <div className="flex items-start">
                                  <div>
                                      <p className="text-gray-800 font-semibold">{notif.title}</p>
                                      <p className="text-gray-600 text-sm mt-1">{notif.message}</p>
                                      <p className="text-gray-400 text-xs mt-2">
                                          {new Date(notif.created_at).toLocaleString()}
                                      </p>
                                  </div>
                              </div>
                          </div>
                      ))
                  ) : (
                      <p className="text-gray-500 text-sm italic">No Offers/Sale at this time.</p>
                  )}
              </div>
          </div>
      </div>
  );
};

export default Notification;
